<template>
  <transition name="slideUp">
    <div
      class="modal-container"
      @click="handleContent($event)"
      v-if="isShowContent"
    >
      <div class="modal-header">
        <div class="pointer" @click="handleClose">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3337 1.34175L11.1587 0.166748L6.50033 4.82508L1.84199 0.166748L0.666992 1.34175L5.32533 6.00008L0.666992 10.6584L1.84199 11.8334L6.50033 7.17508L11.1587 11.8334L12.3337 10.6584L7.67533 6.00008L12.3337 1.34175Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-content-wrapper">
          <div class="modal-content-logo-holder">
            <img src="../../../static/images/logoforapp.png" alt="logo" />
          </div>
          <div class="modal-content-text">
            <p
              class="modal-content-text"
              :class="pos !== 'isSticky' ? 'big' : 'normal'"
            >
              {{ $t("DOWNLOAD") }}
            </p>
            <p>
              {{ $t("GET_REAL_TIME") }}
            </p>
          </div>
          <div class="modal-content-download">
            <div class="modal-content-download__link">
              <a
                href="https://play.google.com/store/apps/details?id=com.asiasport.asia"
                target="_blank"
              >
                <div class="icon-holder">
                  <img src="../../../static/images/googleplay.png" alt="" />
                </div>
                <div class="icon-text">
                  <p>
                    {{ $t("GOOGLE") }}
                  </p>
                  <p class="highlight">Google Play</p>
                </div>
              </a>
              <a
                href="https://apps.apple.com/my/app/asiasport/id1610463486"
                target="_blank"
              >
                <div class="icon-holder">
                  <img src="../../../static/images/apple.png" alt="" />
                </div>
                <div class="icon-text">
                  <p>
                    {{ $t("APPLE") }}
                  </p>
                  <p class="highlight">App Store</p>
                </div>
              </a>
            </div>
            <div class="modal-content-download__scanner">
              <img src="../../../static/images/barcode.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      initShow: true,
      isShowContent: false,
    };
  },
  created() {},
  beforeUnmount() {},
  mounted() {
    window.addEventListener("scroll", this.scrolltoShow);
  },
  methods: {
    handleOpen() {
      this.showModal();
    },
    handleClose() {
      this.hideModal();
    },
    showModal() {
      setTimeout(() => (this.isShowContent = true), 500);
    },
    hideModal() {
      this.isShowContent = false;
    },
    scrolltoShow() {
      if (window.scrollY > 200 && this.initShow) {
        setTimeout(() => (this.isShowContent = true), 500);
        this.initShow = false;
      }
    },
    handleContent(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped>
.modal-container {
  display: flex;
  position: fixed;
  bottom: 10px;
  width: 22rem;
  background-color: var(--color-grey-40);
  background: url("../../../static/images/banner-sticky.png") no-repeat center
    center;
  background-size: cover;
  border-radius: 0.5rem;
  overflow: hidden;
  flex-direction: column;
  padding: 0.75rem;
  box-sizing: border-box;
  min-height: unset;
  z-index: 1;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}
.modal-body {
  height: calc(100% - 2rem);
  display: flex;
  justify-content: flex-end;
}

.modal-content-logo-holder {
  width: 1.75rem;
  height: 2.375rem;
}

.modal-content-logo-holder img,
.modal-content-download__scanner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-content-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-content-text {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.6875rem;
  line-height: 0.8rem;
}

.modal-content-text.big {
  font-size: 1.375rem;
  line-height: 1.625rem;
  font-weight: 700;
  margin-bottom: 6px;
}
.modal-content-text.normal {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin-bottom: 6px;
}

.modal-content-download {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-content-download__link a {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #a6a6a6;
  padding: 0.3rem;
  border-radius: 0.25rem;
  margin: 0.1rem;
  min-width: 6rem;
}

.modal-content-download__scanner {
  width: 3.375rem;
  height: 3.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-download img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.icon-holder {
  width: 0.625rem;
  display: flex;
  align-items: center;
}

.icon-holder img {
  width: 100%;
}
.icon-text {
  font-size: 0.25rem;
  line-height: 0.6rem;
  margin-left: 0.3rem;
}

.icon-text .highlight {
  font-size: 0.65rem;
  line-height: 0.7rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  .modal-container {
    width: 90%;
  }
}
</style>
