<template>
  <div
    v-if="isShow"
    class="modal-wrapper display-flex-center flex-col"
    @click="handleClose"
  >
    <transition name="slideUp">
      <div
        class="modal-container"
        @click="handleContent($event)"
        v-if="isShowContent"
      >
        <div class="modal-header">
          <div class="pointer" @click="handleClose">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.6663 2.68301L21.3163 0.333008L11.9997 9.64968L2.68301 0.333008L0.333008 2.68301L9.64968 11.9997L0.333008 21.3163L2.68301 23.6663L11.9997 14.3497L21.3163 23.6663L23.6663 21.3163L14.3497 11.9997L23.6663 2.68301Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-content-wrapper">
            <div class="modal-content-logo-holder">
              <img src="../../../static/images/logoforapp.png" alt="logo" />
            </div>
            <div class="modal-content-text">
              <p class="modal-content-text__big">{{ $t("DOWNLOAD") }}</p>
              <p>
                {{ $t("WATCH_REAL_TIME") }}
              </p>
            </div>
            <div class="modal-content-download">
              <div class="modal-content-download__link">
                <a
                  href="https://play.google.com/store/apps/details?id=com.asiasport.asia"
                  target="_blank"
                >
                  <div class="icon-holder">
                    <img src="../../../static/images/googleplay.png" alt="" />
                  </div>
                  <div class="icon-text">
                    <p>{{ $t("GOOGLE") }}</p>
                    <p class="highlight">Google Play</p>
                  </div>
                </a>
                <a
                  href="https://apps.apple.com/my/app/asiasport/id1610463486"
                  target="_blank"
                >
                  <div class="icon-holder">
                    <img src="../../../static/images/apple.png" alt="" />
                  </div>
                  <div class="icon-text">
                    <p>{{ $t("APPLE") }}</p>
                    <p class="highlight">App Store</p>
                  </div>
                </a>
              </div>
              <div class="modal-content-download__scanner">
                <img src="../../../static/images/barcode.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      isShow: false,
      isShowContent: false,
    };
  },
  created() {},
  beforeUnmount() {
    document.body.classList.remove("hidden");
  },
  mounted() {
    window.addEventListener("keydown", this.listenKeypressEvent);
  },
  methods: {
    handleOpen() {
      const currentTimeStamp = moment();
      const closeTimeStamp = localStorage.getItem("todayClose")
        ? moment(localStorage.getItem("todayClose"))
        : null;

      if (
        closeTimeStamp &&
        currentTimeStamp.diff(closeTimeStamp, "minutes") >= 1440
      ) {
        document.body.className = "hidden";
        this.showModal();

        setTimeout(() => {
          this.handleClose();
        }, 3000);
      } else if (!closeTimeStamp) {
        document.body.className = "hidden";
        this.showModal();

        setTimeout(() => {
          this.handleClose();
        }, 3000);
      }
    },

    handleClose() {
      this.$emit("handleCloseEvent");
      document.body.classList.remove("hidden");
      this.hideModal();
      localStorage.setItem("todayClose", moment());
    },
    showModal() {
      this.isShow = true;
      setTimeout(() => (this.isShowContent = true), 500);
    },
    hideModal() {
      this.isShowContent = false;
      setTimeout(() => (this.isShow = false), 500);
    },
    listenKeypressEvent(e) {
      if (e.key === "Escape" && this.isModal) {
        this.handleClose();
      }
    },
    handleContent(e) {
      e.stopPropagation();
    },
  },
  watch: {
    $route() {
      //this.initLangauge();
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 57%);
  z-index: 10;
}
.modal-container {
  display: flex;
  width: 51.5rem;
  background-color: var(--color-grey-40);
  background: url("../../../static/images/web-promo-bg.png") no-repeat center
    center;
  background-size: cover;
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: 25.6rem;
  /* max-height: 25rem; */
  max-width: 60rem;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}
.modal-container__fullscreen {
  max-width: 75rem;
  padding: 0;
  position: relative;
  width: auto;
}
.modal-container__fullscreen .modal-header {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}
.modal-body {
  height: calc(100% - 2rem);
  display: flex;
  justify-content: flex-end;
}

.modal-content-wrapper {
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-content-text {
  text-align: center;
  margin-bottom: 1.25rem;
}

.modal-content-text__big {
  font-size: 2.125rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin-bottom: 6px;
}

.modal-content-download {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
}

.modal-content-download__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.modal-content-download__link a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #a6a6a6;
  padding: 0.3rem;
  border-radius: 0.5rem;
  min-width: 7rem;
  margin: 0.2rem;
}

.icon-holder {
  width: 1.1rem;
  display: flex;
  align-items: center;
  margin-right: 0.375rem;
}

.icon-holder img,
.modal-content-download__scanner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.icon-text {
  font-size: 0.625rem;
  line-height: 0.8rem;
}

.icon-text .highlight {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
}

.modal-content-download__scanner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.4rem;
  height: 5.4rem;
}

.modal-content-download img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media (max-width: 768px) {
  .modal-container {
    width: 90%;
  }
}
</style>
