<template>
  <a :href="app.link" target="_blank" class="app-store__wrapper">
      <img class="app-store__icon"  :src="require(`../../../../static/images/icons/${app.icon}.png`)" >
      <div>
          <div class="app-store__download">{{$t(app.downloadTxt)}}</div>
           <div class="app-store__name">{{appType}}{{$t(app.name)}} </div>
     
      </div>
  </a>
</template>

<script>
import config from '@/js/config.js'

export default {
    data() {
        return {
            app: {
                name: 'APP_STORE',
                icon: 'icon_apple',
                downloadTxt: 'DOWNLOAD_ON_THE',
                link: config.app.apple.link
            }
        }
    },
    props: {
        appType: {
            type: String,
            default: 'apple'
        }
    },
    computed:{
        appType(){
             if (this.appType === 'android'){
                 this.app.downloadTxt = "GET_IT_ON";
                 this.app.icon = "icon_google_play";
                 this.app.name = "GOOGLE_PLAY";
                 this.app.link = config.app.android.link
             }
     
        }
    }

}
</script>

<style>
.app-store__wrapper{
    background-color: transparent;
    border: 0.12rem solid #A6A6A6;
    padding: 0.5rem .8rem;
    display: inline-flex;
    color: white;
    border-radius: 0.5rem;
    margin-right: .5rem;
}
.app-store__name{
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1;
}
.app-store__download{
    font-size: 0.6rem;
}
.app-store__icon{
    margin-right: 0.5rem;
    width: 1.5rem;
    object-fit: contain;
}
</style>