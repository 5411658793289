<template>

  <div class="home">
    <div class="container">
      <div class="home-match__nav display-flex hide-mobile">
        <div
          class="match-nav__child"
          :class="{ selected: o.selected }"
          v-for="(o, index) in sportType"
          :key="index"
          @click="selectSportType(o)"
        >
          {{ o.name }}
        </div>
      </div>
    </div>
    <!-- mobile -->
    <!-- <div class="home-nav__sport-type"  @click="selectSportTypeMobile()">// first time publish only available with football so temporary remove it
        <span class="home-nav__sport-type__current-status" >{{mobile.currentSportType.name}}</span>
        <img src="../../assets/images/icons/icon_arrow_down_white.png">
    </div> -->
    <transition name="fade">
      <div
        class="home-nav__sport-type__list-wrapper"
        v-if="mobile.isSelectSportType"
      >
        <div class="home-nav__sport-type__list-header">
          <div>Sport Type</div>
          <div @click="selectSportTypeMobile()">
            <img
              class=""
              src="   ../../assets/images/icons/icon_close_white.png"
            />
          </div>
        </div>
        <div class="home-nav__sport-type__list-container">
          <div
            class="home-nav__sport-type__list-child"
            :class="{ selected: o.selected }"
            v-for="(o, index) in sportType"
            :key="index"
            @click="selectSportType(o)"
          >
            {{ o.name }}
          </div>
        </div>
        <div class="overlay" @click="selectSportTypeMobile()"></div>
      </div>
    </transition>
    <transition name="slide">
      <div class="match-filter__wrapper" v-if="mobile.isSelectMatch">
        <div class="match-filter__header">
          <div @click="selectMatch()">
            <img src="../../../static/images/icons/icon_close_white.png" />
          </div>
          <div>{{ $t("FILTER_BY") }}</div>
          <div
            class="match-filter__tick selected"
            @click="handleFinishSelectLeague()"
          ></div>
        </div>
        <div class="match-filter__body">
          <div class="match-filter__clear">
            <span
              class="btn-primary__outline"
              @click="clearAllSelectMatchChild()"
              >{{ $t("CLEAR_ALL") }}</span
            >
          </div>
          <div
            class="search-mobile__wrapper"
            :class="{ selected: isFilterSearchFoucs }"
          >
            <img
              class="search-mobile__icon"
              src="../../../static/images/icons/icon_search.png"
            />
            <input
              class="search-mobile__input"
              v-model="filter_search_txt_mobile"
              v-on:keyup="handleFilterSearchMobile()"
              @focusin="isFilterSearchFoucs = true"
              @focusout="isFilterSearchFoucs = false"
              :placeholder="$t('SEARCH_COUNTRY_OR_LEAGUE')"
            />
          </div>
          <!-- no filtering serarch text ui -->
          <div
            class="match-filter__list-wrapper"
            v-if="filter_search_txt_mobile.length === 0"
          >
            <div
              class="match-filter__list-container"
              v-for="(o, index) in filterObj"
              :key="index"
            >
              <div class="match-filter__list-title">
                {{ $t(o.categoryDisplayName) }}
              </div>
              <ul class="match-filter__list-child__wrapper">
                <li
                  class="match-filter__list-child"
                  v-for="(level2, index) in o.countries"
                  :key="index"
                >
                  <div class="match-filter__list-child__left">
                    <div class="match-filter__list-child__left-container">
                      <div
                        class="mr-05rem match-filter__list-child__left-select"
                        :class="{ selected: level2.selected }"
                        @click="selectMatchChildAll(o, level2)"
                      ></div>
                    </div>
                    <router-link
                      v-if="level2.rootFlag"
                      :to="{
                        path: `/${currentLocale}/${currentSportType}/${level2.countryKey}/${level2.leagueKey}`,
                        query: {
                          leagueId: level2.leagueId,
                          subLeagueId: level2.subLeagueId,
                        },
                      }"
                      class="display-flex-sb w-100"
                      target="_blank"
                    >
                      <div class="display-flex-center">
                        <Icon
                          :logoUrl="level2.logo"
                          :category="'country'"
                          class="mr-05rem"
                        ></Icon>
                        <div>{{ level2.countryDisplayName }}</div>
                      </div>
                    </router-link>
                    <div
                      v-else
                      class="display-flex-sb w-100"
                      @click="selectMatchChild(o, level2)"
                    >
                      <div class="display-flex-center">
                        <Icon
                          :logoUrl="level2.logo"
                          :category="'country'"
                          class="mr-05rem"
                        ></Icon>
                        <div>{{ level2.countryDisplayName }}</div>
                      </div>
                      <div class="mr-1rem">
                        <img
                          src="../../../static/images/icons/icon_arrow_right.png"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- filtering serarch text ui -->
          <div
            class="match-filter__list-wrapper"
            v-if="filter_search_txt_mobile.length > 0"
          >
            <!-- Country Section -->
            <div
              class="match-filter__list-container"
              v-show="searchCountryListMobile.length > 0"
            >
              <div class="match-filter__list-title">{{ $t("COUNTRY") }}</div>
              <ul class="match-filter__list-child__wrapper">
                <li
                  class="match-filter__list-child"
                  v-for="(o, index) in searchCountryListMobile"
                  :key="index"
                >
                  <div class="match-filter__list-child__left">
                    <div class="match-filter__list-child__left-container">
                      <div
                        class="mr-05rem match-filter__list-child__left-select"
                        :class="{ selected: o.selected }"
                        @click="selectMatchChildAll('', o)"
                      ></div>
                    </div>
                    <router-link
                      :to="{
                        path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
                        query: {
                          leagueId: o.leagueId,
                          subLeagueId: o.subLeagueId,
                        },
                      }"
                      v-if="o.rootFlag"
                      target="_blank"
                      class="display-flex-sb w-100"
                    >
                      <div class="display-flex-center">
                        <Icon
                          :logoUrl="o.logo"
                          :category="'country'"
                          class="mr-05rem"
                        ></Icon>
                        <div>{{ o.countryDisplayName }}</div>
                      </div>
                    </router-link>
                    <div
                      class="display-flex-sb w-100"
                      @click="selectMatchChild('', o)"
                      v-else
                    >
                      <div class="display-flex-center">
                        <Icon
                          :logoUrl="o.logo"
                          :category="'country'"
                          class="mr-05rem"
                        ></Icon>
                        <div>{{ o.countryDisplayName }}</div>
                      </div>
                      <div class="mr-1rem">
                        <img
                          src="../../../static/images/icons/icon_arrow_right.png"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- League Section -->
            <div
              class="match-filter__list-container"
              v-show="searchLeagueListMobile.length > 0"
            >
              <div class="match-filter__list-title">{{ $t("LEAGUE") }}</div>
              <ul class="match-filter__list-child__wrapper">
                <li
                  class="match-filter__list-child"
                  v-for="(o, index) in searchLeagueListMobile"
                  :key="index"
                >
                  <div class="match-filter__list-child__left">
                    <div
                      class="match-filter__list-child__left-container"
                      @click="selectMatchChild2(o)"
                    >
                      <div
                        class="mr-05rem match-filter__list-child__left-select"
                        :class="{ selected: o.selected }"
                      ></div>
                    </div>
                    <router-link
                      :to="{
                        path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
                        query: {
                          leagueId: o.leagueId,
                          subLeagueId: o.subLeagueId,
                        },
                      }"
                      target="_blank"
                      >{{ o.countryDisplayName }}:{{ o.name }}</router-link
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div
              v-show="
                searchCountryListMobile.length === 0 &&
                  searchLeagueListMobile.length === 0
              "
              class="no-data"
            >
              {{ $t("MENU_NOT_AVAILABLE") }}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide">
      <div class="match-filter__wrapper" v-if="mobile.isSelectMatch2">
        <div class="match-filter__header">
          <div @click="closeSelectMatch2()">
            <img src="../../../static/images/icons/icon_arrow_left.png" />
          </div>
          <div>{{ selectLeagueLevel2 }}</div>
          <div></div>
          <!-- <div class="match-filter__tick" :class="{selected: mobile.isSelectedMatchChild}" @click="handleFinishSelectLeague()"> </div> -->
        </div>
        <div class="match-filter__body">
          <div class="match-filter__clear">
            <span
              class="btn-primary__outline"
              @click="clearAllSelectMatchChild2()"
              >{{ $t("CLEAR_ALL") }}</span
            >
          </div>
          <div class="match-filter__list-wrapper">
            <div class="match-filter__list-container">
              <ul class="match-filter__list-child__wrapper">
                <li
                  class="match-filter__list-child"
                  v-for="(o, index) in matchList2.soccerLeagues"
                  :key="index"
                >
                  <div class="match-filter__list-child__left">
                    <div
                      class="match-filter__list-child__left-container"
                      @click="selectMatchChild2(o)"
                    >
                      <div
                        class="mr-05rem match-filter__list-child__left-select"
                        :class="{ selected: o.selected }"
                      ></div>
                    </div>
                    <router-link
                      :to="{
                        path: `/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,
                        query: {
                          leagueId: o.leagueId,
                          subLeagueId: o.subLeagueId,
                        },
                      }"
                      target="_blank"
                    >
                      {{ o.name }}</router-link
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- mobile end -->

    <!-- desktop -->
    <div>
      <Banner></Banner>
      <router-view></router-view>
      <div class="container match-filter__wrapper-desktop">
        <div class="match-filter">
          <div class="match-filter__date" v-if="!isMobile">
            <DatePicker
              v-model="montlyCalenderModel"
              color="#6F8C9B"
              is-dark
              :locale="currentLocale === 'vn' ? 'vi' : currentLocale"
            >
              <template #default="{ inputValue, togglePopover, hidePopover }">
                <span
                  class="nav-pill"
                  :class="{
                    selected: montlyCalender.selected && !isLiveOnlyMatch,
                  }"
                  @click.stop="
                    monthlyCalendarHandle(
                      $event,
                      date,
                      togglePopover,
                      hidePopover
                    )
                  "
                  v-text="montlyCalender.date"
                >
                </span>
              </template>
            </DatePicker>
          </div>
          <div class="match-filter__live-wrapper" @click="handleLiveMatch()">
            <div class="match-filter__live" v-show="!isLiveOnlyMatch">
              <!-- <img src="../../../static/images/btn_live_unselected.gif"> -->
            </div>
            <div
              class="match-filter__live selected"
              v-show="isLiveOnlyMatch"
            ></div>
          </div>
          <weekly-calendar
            :params="currentDate"
            :data="weeklyCalendarData"
            :show="isMobile ? 3 : 4"
            @weeklyDateChange="selectByDateWeekly($event)"
            @isOverWeeklyDateRange="isOverWeeklyDateRange($event)"
          ></weekly-calendar>

          <div class="display-flex">
            <div class="match-filter__date" v-if="isMobile">
              <DatePicker
                v-model="montlyCalenderModel"
                color="#6F8C9B"
                is-dark
                :locale="currentLocale === 'vn' ? 'vi' : currentLocale"
              >
                <template #default="{ inputValue, togglePopover, hidePopover }">
                  <div
                    class="match-filter__icon_wrapper"
                    @click.stop="
                      monthlyCalendarHandle(
                        $event,
                        date,
                        togglePopover,
                        hidePopover
                      )
                    "
                  >
                    <img
                      v-if="montlyCalender.selected && !isLiveOnlyMatch"
                      src="../../../static/images/icons/icon_calendar_selected.png"
                    />
                    <img
                      v-else
                      src="../../../static/images/icons/icon_calendar.png"
                    />
                  </div>
                </template>
              </DatePicker>
            </div>
            <!-- <div v-if="isMobile" class="match-filter__icon_wrapper"  @click="selectMatch()">
                        <img v-if="matchListParams.leagueIdList.length > 0  && !isLiveOnlyMatch" src="../../../static/images/icons/icon_filter_selected.png">
                        <img v-else src="../../../static/images/icons/icon_filter.png">
                    </div> -->
          </div>
        </div>
        <div v-if="isMobile" class="match-date__current">
          {{ montlyCalender.date }}
        </div>
      </div>
      <div class="container">
        <div class="display-flex">
          <div v-if="!isMobile">
            <div
              v-if="league.isLoading"
              class="match-country__wrapper skeleton-table__wrapper"
            >
              <div class="display-flex-sb mb-1rem" v-for="(o, index) in 20">
                <div class="skeleton-child skeleton-league__tick"></div>
                <div class="skeleton-child skeleton-league__name"></div>
              </div>
            </div>
            <div v-else class="match-country__wrapper">
              <div class="display-flex-sb">
                <div class="match-country__header">{{ $t("FILTER_BY") }}</div>
                <div
                  class="match-country__clear-all"
                  @click="handleClearAllLeagueDesktop()"
                >
                  {{ $t("CLEAR_ALL") }}
                </div>
              </div>
              <div class="display-flex match-country__apply">
                <div
                  class="search-wrapper"
                  :class="{ selected: isFilterSearchFoucs }"
                >
                  <img
                    class="search-icon"
                    src="../../../static/images/icons/icon_search.png"
                  />
                  <input
                    class="search-input"
                    v-model="filter_search_txt"
                    v-on:keyup="handleFilterSearch()"
                    @focusin="isFilterSearchFoucs = true"
                    @focusout="isFilterSearchFoucs = false"
                    :placeholder="$t('SEARCH_COUNTRY_OR_LEAGUE')"
                  />
                </div>
              </div>
              <ul class="match-country__body">
                <div v-for="(o, index) in filterObj" :key="index">
                  <li
                    v-for="(level2, index) in o.countries"
                    class="match-country__body-wrapper"
                    :key="index"
                    v-show="level2.isFilteredSelected"
                  >
                    <div class="display-flex-sb pointer">
                      <div class="match-country__body-menu">
                        <div
                          class="match-country__body-menu__tick"
                          :class="{ selected: level2.tickSelected }"
                          @click="selectAllLeagueByCountry(o, level2)"
                        ></div>
                        <router-link
                          v-if="level2.rootFlag"
                          :to="{
                            path: `/${currentLocale}/${currentSportType}/${level2.countryKey}/${level2.leagueKey}`,
                            query: {
                              leagueId: level2.leagueId,
                              subLeagueId: level2.subLeagueId,
                            },
                          }"
                          class="display-flex-align-center"
                          target="_blank"
                          @click="selectCountry(o, level2)"
                        >
                          <Icon
                            :category="'country'"
                            :logoUrl="level2.logo"
                            class="mr-05rem"
                          ></Icon>
                          <span
                            class="match-country__body-menu__name"
                            :title="level2.countryDisplayName"
                            >{{ level2.countryDisplayName }}</span
                          >
                        </router-link>

                        <div
                          v-else
                          class="display-flex-align-center"
                          @click="selectCountry(o, level2)"
                        >
                          <Icon
                            :category="'country'"
                            :logoUrl="level2.logo"
                            class="mr-05rem"
                          ></Icon>
                          <span
                            class="match-country__body-menu__name"
                            :title="level2.countryDisplayName"
                            >{{ level2.countryDisplayName }}</span
                          >

                          <i
                            class="match-country__body-menu__arrow"
                            :class="{ selected: level2.selected }"
                          >
                            <svg
                              width="13"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z"
                                fill="#6F8C9B"
                              />
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>

                    <ul
                      class="match-country__body-menu__sub"
                      v-show="level2.selected && !level2.rootFlag"
                    >
                      <li
                        v-for="(level3, index) in level2.soccerLeagues"
                        :key="index"
                        class="match-country__body-menu__sub-container"
                        v-show="level3.isFilteredSelectedChild"
                      >
                        <div
                          class="match-country__body-menu__tick"
                          :class="{ selected: level3.selected }"
                          @click="selectLeague(o, level2, level3)"
                        ></div>
                        <router-link
                          :to="{
                            path: `/${currentLocale}/${currentSportType}/${level3.countryKey}/${level3.leagueKey}`,
                            query: {
                              leagueId: level3.leagueId,
                              subLeagueId: level3.subLeagueId,
                            },
                          }"
                          class="match-country__body-menu__name"
                          target="_blank"
                          :title="level3.name"
                        >
                          {{ level3.name }}</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <MatchContent ref="matchContentChild" class="match-content__wrapper"  style="min-height:15rem"  @changeMatchContentEvent="changeMatchContentEvent($event)"></MatchContent>
            
          <!-- desktop ranking table -->
          <div v-if="!isMobile" class="match-table__wrapper">
            <div
              class="match-table__title-wrapper"
              v-show="rankingTable.isShow"
            >
              <div>{{ $t("TABLE") }}</div>
              <div class="display-flex">
                <div
                  class="match-table__title-arrow__wrapper tl"
                  :class="{ invisible: !rankingTable.isShowArrow }"
                  @click="handleLeagueListRankingTable('prev')"
                >
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    class="match-table__title-arrow__icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.66542 1.74875L2.42125 5L5.66542 8.25125L4.66667 9.25L0.41667 5L4.66667 0.75L5.66542 1.74875Z"
                    />
                  </svg>
                </div>
                <div class="match-table__title-country">
                  <div class="match-table__title-country-container">
                    <span>{{ rankingTable.currentSelectedLeague }}</span>
                  </div>
                </div>
                <div
                  class="match-table__title-arrow__wrapper"
                  :class="{ invisible: !rankingTable.isShowArrow }"
                  @click="handleLeagueListRankingTable('next')"
                >
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    v-show="rankingTable.isShowArrow"
                    class="match-table__title-arrow__icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              v-show="rankingTable.isLoading"
              class="skeleton-table__wrapper"
            >
              <div class="display-flex-sb mb-1rem" v-for="(o, index) in 10">
                <div class="skeleton-child skeleton-table__left"></div>
                <div class="skeleton-child skeleton-table__right"></div>
              </div>
            </div>
            <!-- <Loading v-show="rankingTable.isLoading"></Loading> -->
            <MatchTable
              :isShowTabs="false"
              :type="'total'"
              :matchTableListData="matchTableList"
              v-show="!rankingTable.isLoading && rankingTable.isShow"
            ></MatchTable>
            <StickyAppBanner pos="isSticky" />
          </div>
          <!-- end desktop ranking table -->
        </div>
      </div>
    </div>

    <div
      class="match-filter__icon-float__wrapper"
      v-if="isMobile"
      :class="{
        activated: isShowFloatingFilter,
        selected: matchListParams.leagueIdList.length > 0,
      }"
      @click="selectMatch()"
    >
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        class="match-filter__icon-float__icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.36487 16.2769V8.41493L0.193006 1.24045C-0.137298 0.834415 -0.100185 0 0.75341 0H17.2167C18.0183 0 18.2039 0.737564 17.7771 1.24045L11.7536 8.22495V12.7083C11.7536 12.9318 11.6794 13.1217 11.4901 13.2707L7.63784 16.8394C7.19248 17.1709 6.36487 16.9921 6.36487 16.2769ZM4.40122 4L7.67866 7.62522C7.79 7.77422 7.86423 7.92695 7.86423 8.1132V14.5857L10.258 12.4065V7.9642C10.258 7.77794 10.3322 7.62522 10.4436 7.47621L13.5688 4H4.40122Z"
        />
      </svg>
    </div>
    <!-- end desktop -->
  </div>
  <!-- <Toastr ref="toastr" :text="'FILTER_UPDATED'" ></Toastr> -->
  <ModalSubscription ref="modalSubscription"></ModalSubscription>
  <!-- <ModalPopup ref="modalPopupRef">
    <div>
      <h2 class="tc mb-1rem">{{ $t("NOTICE_TITLE") }}</h2>
      <p class="mb-1rem">{{ $t("NOTICE_1") }}</p>

      <p class="mb-1rem">{{ $t("NOTICE_2") }}</p>

      <p class="mb-1rem tc">{{ $t("NOTICE_3") }}</p>
    </div>
  </ModalPopup> -->
  <ModalforApp ref="modalPopupRef" isModal="false" />
    <SplashSceenMobile></SplashSceenMobile>

</template>

<script>
// @ is an alias to /src
import MatchContent from "@/components/MatchContent2.vue";
import SplashSceenMobile from '@/components/mobile/SplashSceen.vue'

import WeeklyCalendar from "@/components/WeeklyCalendar.vue";
import ModalSubscription from "@/components/modal/Subscription.vue";
import Toastr from "@/components/toastr/Toastr.vue";
import { DatePicker } from "@/lib/v-calendar";
import ModalPopup from "@/components/modal/ModalPopup.vue";
import ModalforApp from "../../components/modal/ModalforApp.vue";
import StickyAppBanner from "../../components/modal/StickyAppBanner.vue";

import $ from "jquery";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import MatchTable from "@/components/match/Table.vue";
import Banner from "@/components/Banner.vue";
import config from "@/js/config.js";

import { ref } from "vue";
 
let hideToggleElement;
let hideToggleTarget;

let isScrolling;
export default {
  name: "Home",
  components: {
    MatchContent,
    MatchTable,
    WeeklyCalendar,
    Banner,
    ModalSubscription,
    Toastr,
    DatePicker,
    ModalPopup,
    SplashSceenMobile,

    ModalforApp,
    StickyAppBanner,
  },
  data() {
    return {
      isFirstimeLoadMontlyCalendar: false,
      displayDateFormat: config.displayDateFormat,
      apiParamDateFormat: config.apiParamDateFormat,
      montlyCalender: {
        date: "",
        selected: false,
        isFirstime: false,
      },
      montlyCalenderModel: "",
      currentDate: "",
      mobile: {
        isSelectSportType: false,
        currentSportType: {},
        isSelectMatch: false,
        isSelectMatch2: false,
        isSelectedMatchChild: false,
      },
      matchList2: {
        soccerLeagues: [],
      },
      sportType: [
        {
          id: 1,
          name: "Football",
          selected: true,
        },
        {
          id: 2,
          name: "Hockey",
          selected: false,
        },
        {
          id: 3,
          name: "Basketball",
          selected: false,
        },
        {
          id: 4,
          name: "Cricket",
          selected: false,
        },
      ],
      league: {
        isLoading: false,
      },
      leagueList: [],

      filterObj: [],
      cloneFilterObj: [],
      selectedLeagueList: [],
      matchListParams: {
        leagueIdList: [],
        date: "",
      },

      selectLeagueLevel2: "",
      leagueListRankingTable: [],

      currentSelectedLeagueListRankingTable: "",
      matchTableList: [],
      matchRankingTableParams: "",
      rankingTable: {
        isShow: false,
        isShowArrow: true,
        isLoading: false,
        currentSelectedLeagueIndex: 0,
        list: [],
        currentSelectedLeague: "",
      },

      filter_search_txt: "",
      filter_search_txt_mobile: "",
      searchCountryListMobile: [],
      searchLeagueListMobile: [],
      isFilterSearchFoucs: false,

      isCallMatchContentInterval: false,
      matchContentInterval: null,

      callByDateWeekly: false,

      isLiveOnlyMatch: false, //default not show live match

      isShowFloatingFilter: true,
    };
  },

  computed: {
    ...mapGetters([
      "isMobile",
      "currentLocale",
      "currentSportType",
      "homePageLeagueParams",
      "userInfo",
      "isLogin",
    ]),
  },
  watch: {
    matchListParams: {
      deep: true,
      handler(newVal, oldVal) {
        // this.getLeagueListForRankingTable(newVal.leagueIdList);
      },
    },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        let isGetNewData = true;
        this.init(isGetNewData);
        // this.getLeagueListForRankingTable(newVal.leagueIdList);
      },
    },
    montlyCalenderModel: {
      deep: true,
      handler(newVal, oldVal) {
        if (!this.isFirstimeLoadMontlyCalendar && !this.callByDateWeekly) {
          if (typeof hideToggleElement !== "undefined") {
            setTimeout(() => hideToggleElement({ ref: hideToggleTarget }), 500); // noob fix vcalendar bug (somethimes dont close the calendar automatic);
          }

          this.syncMonthlyWeeklyCalendar(newVal === null ? oldVal : newVal);
        } else {
          this.callByDateWeekly = false;
        }

        this.isFirstimeLoadMontlyCalendar = false;
      },
    },

    $route() {
      this.montlyCalender.date = moment(this.matchListParams.date).format(
        this.displayDateFormat
      ); // when change multilanguage options translate the display date also
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    clearInterval(this.matchContentInterval);
  },

  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "getSoccerLeagueRanking",
      "homePageLeaugeParamsChangeEvent",
      "getLeagueList",
      "getRetrieveUserInfo",
      "setUserData",
      "toastrChangeEvent",
      "commonVerifyLogin",
    ]),
    init(isGetNewData) {
      this.commonVerifyLogin();

      //get league filter data
      this.initLeagueFilterAndDate(isGetNewData);

      //get ranking data
      this.getSoccerLeagueRankingData();
      
      this.commitParamsToMatchContentIntervel();

      this.getRetrieveUserInfoData();

      this.showPopUpNotification();
      // this.handleClearAllLeagueDesktop(isGetNewData);

      // this.showModalSubscription();
    },
    showPopUpNotification() {
      if (!this.isMobile) {
        this.$refs.modalPopupRef.handleOpen();
      }
      
    },
    selectByDateWeekly(e) {
      this.callByDateWeekly = true;
      this.syncMonthlyWeeklyCalendar(e.fullDate);
    },

    isOverWeeklyDateRange(e) {
      this.montlyCalender.selected = e;
    },

    initLeagueFilterAndDate(isGetNewData) {
      this.league.isLoading = true;
      setTimeout(() => {
        this.league.isLoading = false;
      }, 100);
      let date = new Date();
      let sessionStorageMatchesPage =
        sessionStorage.getItem("matchesPage") === null
          ? null
          : JSON.parse(sessionStorage.getItem("matchesPage"));

      if (isGetNewData) {
        this.matchListParams.date = date;
        this.getLeagueListData();
        this.filter_search_txt = "";
        this.matchListParams.leagueIdList = [];
      } else {
        if (sessionStorageMatchesPage !== null) {
          if (this.currentLocale === sessionStorageMatchesPage.lang) {
            this.filterObj = sessionStorageMatchesPage.filterObj;
            let result = this.filterObj;

            for (let level1 = 0; level1 < result.length; level1++) {
              for (
                let level2 = 0;
                level2 < result[level1].countries.length;
                level2++
              ) {
                this.filterObj[level1].countries[level2].tickSelect = false; // for desktop version filter to differential is expand the country tab or select all country list (tick select)
                this.filterObj[level1].countries[
                  level2
                ].isFilteredSelected = true; //to differential is to show by seaching filter
                for (
                  let level3 = 0;
                  level3 <
                  result[level1].countries[level2].soccerLeagues.length;
                  level3++
                ) {
                  let level3Obj = this.filterObj[level1].countries[level2]
                    .soccerLeagues[level3];
                  level3Obj.searchKey =
                    this.filterObj[level1].countries[level2]
                      .countryDisplayName +
                    " " +
                    level3Obj.name; // add search key so that can search with country and league name together
                  level3Obj.isFilteredSelectedChild = true;
                }
              }
            }

            this.matchListParams = sessionStorageMatchesPage.matchListParams;

            date = this.matchListParams.date;
          } else {
            this.matchListParams.date = date;
            this.getLeagueListData();
          }
        } else {
          this.matchListParams.date = date;
          this.getLeagueListData();
        }
      }

      this.isFirstimeLoadMontlyCalendar = true;
      this.syncMonthlyWeeklyCalendar(date);
    },

    monthlyCalendarHandle(e, date, toggle, hideToggle) {
      toggle({ ref: e.target });

      hideToggleTarget = e.target;
      hideToggleElement = hideToggle;
    },
    // get user info
    async getRetrieveUserInfoData() {
      if (this.isLogin) {
        let params = {
          token: this.userInfo.token,
          timeZone: this.$tools.getCurrentTimeZone(),
        };
        const result = await this.getRetrieveUserInfo(params);
        this.setUserData(result.result.data);
      }
    },
    syncMonthlyWeeklyCalendar(date) {
      this.currentDate = moment(date).format(this.apiParamDateFormat); //for weekly calendar usage
      this.montlyCalender.date = moment(date).format(this.displayDateFormat); //for montly calendar usage

      this.montlyCalenderModel = date;

      this.matchListParams.date = this.currentDate;

      this.isLiveOnlyMatch = false;
      this.commitParamsToMatchContent();
    },
    //end montly calendar

    handleFilterSearch() {
      let isAnyChildMatch = false;
      let result = this.filterObj;
      let targetText = this.filter_search_txt.trim().toUpperCase();

      for (let level1 = 0; level1 < this.filterObj.length; level1++) {
        for (
          let level2 = 0;
          level2 < this.filterObj[level1].countries.length;
          level2++
        ) {
          // this.filterObj[level1].countries[level2].tickSelected = false;   // for desktop version filter to differential is expand the country tab or select all country list (tick select)
          this.filterObj[level1].countries[level2].isFilteredSelected = true; //to differential is to show by seaching filter
          this.filterObj[level1].countries[level2].selected = false;
          for (
            let level3 = 0;
            level3 <
            this.filterObj[level1].countries[level2].soccerLeagues.length;
            level3++
          ) {
            let level3Obj = this.filterObj[level1].countries[level2]
              .soccerLeagues[level3];
            level3Obj.searchKey =
              this.filterObj[level1].countries[level2].countryDisplayName +
              " " +
              level3Obj.name; // add search key so that can search with country and league name together
            //level3Obj.selected = false;
            level3Obj.isFilteredSelectedChild = true;
          }
        }
      }

      if (targetText != "") {
        for (let level1 = 0; level1 < this.filterObj.length; level1++) {
          for (
            let level2 = 0;
            level2 < this.filterObj[level1].countries.length;
            level2++
          ) {
            let level2_Obj = this.filterObj[level1].countries[level2];
            if (
              level2_Obj.countryDisplayName.toUpperCase().search(targetText) >
              -1
            ) {
              level2_Obj.isFilteredSelected = true;
              level2_Obj.selected = true;
            } else {
              isAnyChildMatch = false;
              if (level2_Obj.soccerLeagues.length > 0) {
                for (
                  let level3 = 0;
                  level3 <
                  this.filterObj[level1].countries[level2].soccerLeagues.length;
                  level3++
                ) {
                  if (
                    this.filterObj[level1].countries[level2].soccerLeagues[
                      level3
                    ].searchKey
                      .toUpperCase()
                      .search(targetText) > -1
                  ) {
                    this.filterObj[level1].countries[
                      level2
                    ].isFilteredSelected = true;
                    this.filterObj[level1].countries[level2].selected = true;

                    this.filterObj[level1].countries[level2].soccerLeagues[
                      level3
                    ].isFilteredSelectedChild = true;
                    isAnyChildMatch = true;
                  } else {
                    if (isAnyChildMatch) {
                      this.filterObj[level1].countries[
                        level2
                      ].isFilteredSelected = true;
                      this.filterObj[level1].countries[level2].selected = true;
                      if (
                        this.filterObj[level1].countries[level2].soccerLeagues[
                          level3
                        ].searchKey
                          .toUpperCase()
                          .search(targetText) > -1
                      ) {
                        //this.filterObj[level1].countries[level2].soccerLeagues[level3].isFilteredSelectedChild = true;
                      } else {
                        this.filterObj[level1].countries[level2].soccerLeagues[
                          level3
                        ].isFilteredSelectedChild = false;
                      }
                    } else {
                      this.filterObj[level1].countries[
                        level2
                      ].isFilteredSelected = false;

                      this.filterObj[level1].countries[level2].soccerLeagues[
                        level3
                      ].isFilteredSelectedChild = false;
                    }
                  }
                }
              } else {
                level2_Obj.isFilteredSelected = false;
                level2_Obj.selected = false;
              }
            }
          }
        }
      } else {
        //  this.resetFilterObj();
      }
    },

    handleFilterSearchMobile() {
      let targetText = this.filter_search_txt_mobile.trim().toUpperCase();

      //search for country
      this.searchCountryListMobile = [];
      for (let level1 = 0; level1 < this.filterObj.length; level1++) {
        for (
          let level2 = 0;
          level2 < this.filterObj[level1].countries.length;
          level2++
        ) {
          let level2Obj = this.filterObj[level1].countries[level2];
          if (
            level2Obj.countryDisplayName.toUpperCase().search(targetText) > -1
          ) {
            //search at country level (parent)
            //this.searchCountryListMobile.push(level2Obj);
          }
        }
      }

      this.searchLeagueListMobile = [];
      for (let level1 = 0; level1 < this.filterObj.length; level1++) {
        for (
          let level2 = 0;
          level2 < this.filterObj[level1].countries.length;
          level2++
        ) {
          let level2Obj = this.filterObj[level1].countries[level2];
          for (
            let level3 = 0;
            level3 < level2Obj.soccerLeagues.length;
            level3++
          ) {
            if (
              level2Obj.soccerLeagues[level3].searchKey
                .toUpperCase()
                .search(targetText) > -1
            ) {
              level2Obj.soccerLeagues[level3].countryDisplayName =
                level2Obj.countryDisplayName;

              //push data for country list search
              if (this.searchCountryListMobile.length === 0) {
                this.searchCountryListMobile.push(level2Obj);
              } else {
                if (this.searchCountryListMobile.indexOf(level2Obj) === -1) {
                  this.searchCountryListMobile.push(level2Obj);
                }
              }

              //push data for league list search
              if (!level2Obj.rootFlag) {
                this.searchLeagueListMobile.push(
                  level2Obj.soccerLeagues[level3]
                );
              }
            }
          }
        }
      }
    },

    showModalSubscription() {
      this.$refs.modalSubscription.handleOpen();
    },

    async getLeagueListData() {
      const response = await this.getLeagueList();

      let result = response.result.categories;
      this.filterObj = result;
      this.storeFilterData();
      this.resetFilterObj();

      // for (let level1=0; level1< result.length; level1++){
      //     for (let level2=0; level2 < result[level1].countries.length; level2++) {
      //         this.filterObj[level1].countries[level2].tickSelect = false;   // for desktop version filter to differential is expand the country tab or select all country list (tick select)
      //         this.filterObj[level1].countries[level2].isFilteredSelected = true;  //to differential is to show by seaching filter
      //         for (let level3=0; level3 < result[level1].countries[level2].soccerLeagues.length; level3++) {
      //             let level3Obj = this.filterObj[level1].countries[level2].soccerLeagues[level3];
      //             level3Obj.searchKey = this.filterObj[level1].countries[level2].countryDisplayName + ' ' +level3Obj.name; // add search key so that can search with country and league name together
      //             level3Obj.isFilteredSelectedChild = true;
      //         }
      //     }
      // }

      for (let i = 0; i < result.length; i++) {
        for (let x = 0; x < result[i].countries.length; x++) {
          this.leagueList.push(result[i].countries);
        }
      }
    },
    //desktop league filter
    selectCountry(o, level2) {
      level2.selected = !level2.selected;
    },

    resetFilterObj() {
      // this.filter_search_txt = "";
      for (let level1 = 0; level1 < this.filterObj.length; level1++) {
        for (
          let level2 = 0;
          level2 < this.filterObj[level1].countries.length;
          level2++
        ) {
          this.filterObj[level1].countries[level2].tickSelected = false; // for desktop version filter to differential is expand the country tab or select all country list (tick select)
          this.filterObj[level1].countries[level2].isFilteredSelected = true; //to differential is to show by seaching filter
          this.filterObj[level1].countries[level2].selected = false;
          for (
            let level3 = 0;
            level3 <
            this.filterObj[level1].countries[level2].soccerLeagues.length;
            level3++
          ) {
            let level3Obj = this.filterObj[level1].countries[level2]
              .soccerLeagues[level3];
            level3Obj.searchKey =
              this.filterObj[level1].countries[level2].countryDisplayName +
              " " +
              level3Obj.name; // add search key so that can search with country and league name together
            level3Obj.selected = false;
            level3Obj.isFilteredSelectedChild = true;
          }
        }
      }
    },

    handleClearAllLeagueDesktop(isGetNewData) {
      this.filter_search_txt = "";
      this.resetFilterObj();

      this.matchListParams.leagueIdList = [];
      this.commitParamsToMatchContent();
    },

    selectAllLeagueByCountry(o, level2) {
      level2.tickSelected = !level2.tickSelected;

      if (!level2.tickSelected) {
        //remove selected league

        for (let i = 0; i < level2.soccerLeagues.length; i++) {
          if (level2.soccerLeagues[i].isFilteredSelectedChild) {
            let index = this.matchListParams.leagueIdList.indexOf(
              level2.soccerLeagues[i].leagueId
            );

            if (index !== -1) {
              this.matchListParams.leagueIdList.splice(index, 1);
            }

            level2.soccerLeagues[i].selected = level2.tickSelected;
          }
        }
      } else {
        //add selected league
        for (let i = 0; i < level2.soccerLeagues.length; i++) {
          if (level2.soccerLeagues[i].isFilteredSelectedChild) {
            let index = this.matchListParams.leagueIdList.indexOf(
              level2.soccerLeagues[i].leagueId
            );
            if (index === -1) {
              //alreay at the array list so no need to push it
              this.matchListParams.leagueIdList.push(
                level2.soccerLeagues[i].leagueId
              );
            }
            level2.soccerLeagues[i].selected = level2.tickSelected;
          }
        }
      }
      this.commitParamsToMatchContent();
    },

    selectLeague(o, level2, level3) {
      let index = this.matchListParams.leagueIdList.indexOf(level3.leagueId);
      if (index !== -1) {
        this.matchListParams.leagueIdList.splice(index, 1);
      } else {
        this.matchListParams.leagueIdList.push(level3.leagueId);
      }
      level3.selected = !level3.selected;

      //sync child league list and parent
      let count = 0;
      for (let i = 0; i < level2.soccerLeagues.length; i++) {
        if (level2.soccerLeagues[i].selected) {
          count++;
        }
      }
      if (level2.soccerLeagues.length === count) {
        level2.tickSelected = true;
      } else {
        level2.tickSelected = false;
      }

      this.commitParamsToMatchContent();
    },

    handleFinishSelectLeagueDesktop() {
      this.commitParamsToMatchContent();
    },
    //end desktop league filter

    selectMatch() {
      this.filter_search_txt_mobile = "";
      this.mobile.isSelectMatch = !this.mobile.isSelectMatch;
      for (let i = 0; i < this.filterObj.length; i++) {
        for (let x = 0; x < this.filterObj[i].countries.length; x++) {
          for (
            let y = 0;
            y < this.filterObj[i].countries[x].soccerLeagues.length;
            y++
          ) {
            if (
              this.filterObj[i].countries[x].soccerLeagues[y].selected === true
            ) {
              this.selectedLeagueList.push(
                this.filterObj[i].countries[x].soccerLeagues[y].leagueId
              );
            }
          }
        }
      }
    },

    clearAllSelectMatchChild() {
      this.filter_search_txt_mobile = "";
      for (let i = 0; i < this.filterObj.length; i++) {
        for (let x = 0; x < this.filterObj[i].countries.length; x++) {
          this.filterObj[i].countries[x].selected = false;
          this.filterObj[i].countries[x].tickSelected = false;
          for (
            let y = 0;
            y < this.filterObj[i].countries[x].soccerLeagues.length;
            y++
          ) {
            this.filterObj[i].countries[x].soccerLeagues[y].selected = false;
          }
        }
      }
      this.matchListParams.leagueIdList = [];
    },

    selectMatchChildAll(o, level2) {
      level2.selected = !level2.selected;

      if (!level2.selected) {
        //remove selected league

        for (let i = 0; i < level2.soccerLeagues.length; i++) {
          let index = this.matchListParams.leagueIdList.indexOf(
            level2.soccerLeagues[i].leagueId
          );

          if (index !== -1) {
            this.matchListParams.leagueIdList.splice(index, 1);
          }
          level2.soccerLeagues[i].selected = level2.selected;
        }
      } else {
        //add selected league
        for (let i = 0; i < level2.soccerLeagues.length; i++) {
          let index = this.matchListParams.leagueIdList.indexOf(
            level2.soccerLeagues[i].leagueId
          );
          if (index === -1) {
            //alreay at the array list so no need to push it
            this.matchListParams.leagueIdList.push(
              level2.soccerLeagues[i].leagueId
            );
          }
          level2.soccerLeagues[i].selected = level2.selected;
        }
      }

      level2.soccerLeagues.forEach((x) => {
        // x.selected = false;
        //this.selectMatchChild2(x)
        // x.selected= level2.selected;
      });
    },

    selectMatchChild(o, level2) {
      this.selectLeagueLevel2 = level2.countryDisplayName;
      this.mobile.isSelectMatch2 = !this.mobile.isSelectMatch2;
      this.matchList2 = level2;
    },

    selectMatchChild2(level2, o) {
      level2.selected = !level2.selected;
      let index = this.matchListParams.leagueIdList.indexOf(level2.leagueId);
      if (level2.selected) {
        // mean need to add selected item to array
        if (index == -1) {
          //if item no yet added to array
          this.matchListParams.leagueIdList.push(level2.leagueId);
        }
      } else {
        if (index !== -1) {
          this.matchListParams.leagueIdList.splice(index, 1);
        }
      }

      // if (index !== -1) {//remove league
      //      this.matchListParams.leagueIdList.splice(index, 1);
      // }else{// add league
      //     this.matchListParams.leagueIdList.push(level2.leagueId)
      // }

      //check if select all child if yes then parent selected to be true;
      this.syncMatchChildAndMatchChild2();
    },

    syncMatchChildAndMatchChild2() {
      let countMatchList2Child = 0;

      for (let i = 0; i < this.matchList2.soccerLeagues.length; i++) {
        if (this.matchList2.soccerLeagues[i].selected == true) {
          countMatchList2Child++;
        }
      }

      if (countMatchList2Child === this.matchList2.soccerLeagues.length) {
        this.matchList2.selected = true;
      } else {
        this.matchList2.selected = false;
      }
    },
    handleFinishSelectLeague() {
      //this.homePageLeaugeParamsChangeEvent(this.matchListParams);
      this.commitParamsToMatchContent();
      this.handleCloseFilter();
    },

    clearAllSelectMatchChild2() {
      for (let i = 0; i < this.matchList2.soccerLeagues.length; i++) {
        let index = this.matchListParams.leagueIdList.indexOf(
          this.matchList2.soccerLeagues[i].leagueId
        );
        if (index !== -1) {
          this.matchListParams.leagueIdList.splice(index, 1);
        }

        this.matchList2.soccerLeagues[i].selected = false;
      }
      this.syncMatchChildAndMatchChild2();
    },

    handleCloseFilter() {
      this.mobile.isSelectMatch = false;
      this.closeSelectMatch2();
      setTimeout(() => {
        let params = {
          isOpen: true,
          msg: "FILTER_UPDATED",
        };

        this.toastrChangeEvent(params);
        // this.$refs.toastr.handleOpen(); //animation more smooth after delay 0.1s
      }, 100);
    },

    closeSelectMatch2() {
      this.mobile.isSelectMatch2 = false;
    },

    selectSportType(o) {
      this.sportType.forEach((x) => {
        x.selected = false;
      });
      o.selected = true;
      this.mobile.currentSportType = o;
      this.selectSportTypeMobile();
    },

    selectSportTypeMobile() {
      this.mobile.isSelectSportType = !this.mobile.isSelectSportType;
    },

    commitParamsToMatchContentIntervel() {
      this.matchContentInterval = setInterval(() => {
        let props = this.matchListParams;

        const theDate = moment(props.date).startOf("day");
        const today = moment().startOf("day");
        const diffDay = theDate.diff(today, "days");

        if (diffDay === 0 || diffDay === -1 || this.isLiveOnlyMatch) {
          this.commitParamsToMatchContentInterval();
        }
      }, 15000);
    },

    commitParamsToMatchContentInterval() {
      let matchesPage = {
        filterObj: this.filterObj,
        matchListParams: this.matchListParams,
        lang: this.currentLocale,
      };

      this.matchListParams.isCallInterval = true;
      this.isCallMatchContentInterval = true;

      if (this.filterObj.length !== 0) {
        // not first time
        sessionStorage.setItem("matchesPage", JSON.stringify(matchesPage));
      }

      this.$refs.matchContentChild.getMatchListData(this.matchListParams);
    },
    storeFilterData() {
      //paramerter for getMatchlist api
      this.matchListParams.isCallInterval = false;
      this.matchListParams.isLiveOnly = this.isLiveOnlyMatch;

      //for localhost store
      let matchesPage = {
        filterObj: this.filterObj,
        matchListParams: this.matchListParams,
        lang: this.currentLocale,
      };

      this.isCallMatchContentInterval = false;

      if (this.filterObj.length !== 0) {
        // not first time
        sessionStorage.setItem("matchesPage", JSON.stringify(matchesPage));
      }
    },

    commitParamsToMatchContent(isLiveOnlyMatch) {
      this.storeFilterData();
      // if (!isLiveOnlyMatch) { //if call by league filter
      //     this.isLiveOnlyMatch = false;
      //     this.handleWeeklyCalanderWithLiveMatch();
      // } else {
      //     this.isLiveOnlyMatch = true;
      // }
      //pass parameter to matchContent component for getMatchList api

      this.$refs.matchContentChild.getMatchListData(this.matchListParams);
    },

    changeMatchContentEvent(result) {
      if (!this.isMobile) {
   
        if (result.length === 0) {
          this.rankingTable.isShow = false;
        } else {
          if (!this.isCallMatchContentInterval) {
            let leagueList = result.map((x) => x.leagueId).join();
           // this.getSoccerLeagueRankingData(leagueList);
          }
        }
      }
    },

    async getSoccerLeagueRankingData(param) {
      //this.rankingTable.isShow= true;
      this.rankingTable.isLoading = true;
      let params = {
        leagueIdList: '',
      };
      const result = await this.getSoccerLeagueRanking(params);
      this.getLeagueListForRankingTable(result.result);
      this.rankingTable.isLoading = false;
    },

    //handle ranking table
    getLeagueListForRankingTable(result) {
      if (result.length === 0) {
        this.rankingTable.isShow = false;
      } else {
        this.rankingTable.isShow = true;
        this.rankingTable.list = result;
        this.handleLeagueListRankingTable();
      }
    },

    handleLeagueListRankingTable(action) {
      let limit = this.rankingTable.list.length - 1;
      let index = 0;
      if (limit === 0) {
        this.rankingTable.isShowArrow = false;
      } else {
        this.rankingTable.isShowArrow = true;
      }
      if (action === "next") {
        if (this.rankingTable.currentSelectedLeagueIndex < limit) {
          this.rankingTable.currentSelectedLeagueIndex++;
        } else {
          this.rankingTable.currentSelectedLeagueIndex = 0;
        }
        index = this.rankingTable.currentSelectedLeagueIndex;
      } else if (action === "prev") {
        if (this.rankingTable.currentSelectedLeagueIndex > 0) {
          this.rankingTable.currentSelectedLeagueIndex--;
        } else {
          this.rankingTable.currentSelectedLeagueIndex = limit;
        }

        index = this.rankingTable.currentSelectedLeagueIndex;
      } else {
        index = 0;
      }

      this.rankingTable.currentSelectedLeague = this.rankingTable.list[
        index
      ].tableName;
      this.matchTableList = this.rankingTable.list[index];
    },

    //live match
    handleLiveMatch() {
      this.isLiveOnlyMatch = !this.isLiveOnlyMatch;

      this.handleWeeklyCalanderWithLiveMatch();

      if (this.isLiveOnlyMatch) {
        //if show live match
        this.commitParamsToMatchContent(this.isLiveOnlyMatch);
      } else {
        this.commitParamsToMatchContent();
      }
    },

    handleWeeklyCalanderWithLiveMatch() {
      //if show live match weekly calendar deselected all date, otherwise show previous date
      this.weeklyCalendarData = {
        currentDate: this.currentDate,
        isLiveOnlyMatch: this.isLiveOnlyMatch,
      };
    },

    //handle scroll (when scrolling down it will disappear, when stop scrolling it will appear, by default it is fixed appearance)
    handleScroll(event) {
      if (event.type === "scroll") {
        this.isShowFloatingFilter = false;
      } else {
        this.isShowFloatingFilter = true;
      }

      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(() => {
        this.isShowFloatingFilter = true;
      }, 300);
    },
  },
};
</script>

<style scoped>
.date {
  background-color: black;
}
.home-nav__match-wrapper {
  display: block;
}
.match-filter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.8rem;
}
.match-filter__right-wrapper {
  display: flex;
  align-items: center;
  margin-left: 7.2rem;
  position: relative;
}
.match-filter__right-divider {
  width: 100%;
  height: 0.1rem;
  background-color: black;
}
.match-filter__icon_wrapper {
  width: 1.5rem;
  height: 1.5rem;
  /* padding: .3rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
}
.match-filter__icon_wrapper.selected {
  background-color: #6f8c9b;
}
.match-table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.88rem;
  margin-bottom: 0.4rem;
}
.match-table__header-country {
  font-size: 0.88rem;
}
.match-table__header-country__seperator {
  margin: 0 0.3rem;
}
.match-content__wrapper {
  width: 100% !important;
  margin: 0 0.8rem;
}
.match-country__wrapper {
  width: 13rem;
}
.match-country__header {
  font-size: 0.6rem;
  margin-bottom: 0.3rem;
}
.match-country__clear-all {
  font-size: 0.6rem;
  margin-right: 1rem;
  cursor: pointer;
  margin-bottom: 0.3rem;
  color: var(--color-grey-light);
}
.match-country__clear-all:hover {
  color: var(--color-hover);
}
.match-country__body-wrapper {
  margin-bottom: 0.3rem;
}
.match-country__body-menu {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
}
.match-country__body-menu > img {
  margin-right: 0.3rem;
  width: 1.35rem;
}
.match-country__body-menu__arrow {
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.match-country__body-menu__arrow.selected {
  transform: rotate(180deg);
}
.match-country__body-menu__tick {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background: url("../../../static/images/icons/icon_filter_tick_unselect.png")
    center/cover no-repeat;
}
.match-country__body-menu__tick.selected {
  background: url("../../../static/images/icons/icon_filter_tick_selected.png")
    center/cover no-repeat;
}
.match-country__body-menu__name {
  width: 7.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.match-country__body-menu__name:hover {
  color: var(--color-hover);
}
.match-country__body-menu__sub {
  margin-left: 1.7rem;
  font-size: 0.7rem;
  color: #abbcc4;
}
.match-country__body-menu__sub > a {
  margin: 0.5rem 0;
  display: block;
  cursor: pointer;
}
.match-country__body-menu__sub-container {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  cursor: pointer;
}
.match-country__apply {
  margin: 0.5rem 0 0.8rem 0;
}
.match-table__wrapper {
  width: 22rem;
  position: relative;
}
.match-table__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.88rem;
  margin-bottom: 0.5rem;
}
.match-table__title-country {
  display: flex;
  width: 12rem;
  line-height: 1rem;
}
.match-table__title-country-container {
  transition: all 0.3s;
  min-width: 100%;

  font-size: 0.7rem;
  text-align: center;
}
.match-table__title-arrow__wrapper {
  width: 2rem;
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.match-table__title-arrow__icon {
  fill: white;
}
.match-table__title-arrow__wrapper:hover .match-table__title-arrow__icon {
  fill: var(--color-hover);
}
.match-table__title-arrow__wrapper.tl {
  text-align: left;
}
@media (min-width: 768px) {
  .match-filter {
    margin: 0.8rem 0;
    justify-content: unset;
  }
  .match-filter__date {
    width: 13rem;
  }
  .match-content__wrapper {
    width: 37rem;
    margin-right: 2rem;
  }
}
</style>
