<template>
    <div class="display-flex" :class="{'mb-03rem': index < oddsMatchDataList.length - 1}"
         v-for="(o, index) in oddsMatchDataList" :key="index"
         >
    
        <div class="home-match__static-card__score"
             :class="dataColorFilter(o, level2)" 
             v-for="(level2) in isShowBriefData ? 1 : 3" :key="level2">
             {{ dataFilter(o, level2) }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        oddsMatchData: {
            type: Object
        },
        isShowBriefData: {
            type: Boolean
        }
    },
    data() {
        return {
            defaultDataDisplay: '-',

            oddsMatchDataList: [
                {
                    name: 'handicap',
                    status: 'latest',
                },
                {
                    name: 'overUnder',
                    status: 'latest',
                }
            ]  
        }
    },
    methods: {
        dataFilter(o, index) {
            let data = this.oddsMatchData;
            let result;
            let oddsName;

            if (data) {
                oddsName = data[o.name][o.status];

                if (oddsName) {
                    //display sequence: odds2 odds1 odds3 value
                    if (index === 1) {
                        index = 2
                    } else if (index === 2) {
                        index = 1
                    }

                    //Show odds1 value when the selected date is before today
                    if (this.isShowBriefData) {
                        index = 1
                    }

                    result = oddsName[`odds${index}Value`];
                    return result ? result : result === 0 ? '0' : this.defaultDataDisplay 
                } else {
                    return this.defaultDataDisplay
                }  
            } else {
                 return this.defaultDataDisplay
            }
        },

        dataColorFilter(o, index) {
            let data = this.oddsMatchData;
            let result;
            let oddsName;

            if (data) {
                oddsName = data[o.name][o.status];

                if (oddsName) {
                    //display sequence: odds2 odds1 odds3 value
                    if (index === 1) {
                        index = 2
                    } else if (index === 2) {
                        index = 1
                    }
                    
                    //Show odds1 value when the selected date is before today
                    if (this.isShowBriefData) {
                        return ''
                    } else {
                        result = oddsName[`odds${index}Trend`]; 
                        return `odds-${result}`
                    }

                   
                }
            }
        } 
    },

}
</script>

<style>
    .home-match__static-card__score {
        padding: 0 0.2rem;
        font-size: .6rem;
        width: 2rem;
        text-align: center;
    }
</style>