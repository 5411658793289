<template>      

  <div class="splash-sceen__wrapper" v-if="isShow" v-show="isMobile">
      <div class="splash-sceen__close" @click="closeSplashSceen()"><img src="../../../static/images/icons/icon_cancel_circle.png"></div>
      <div class="splash-sceen__body">
        <div class="display-flex-center flex-col mb-2rem">
            <MainLogoIcon></MainLogoIcon>
            <MainLogo></MainLogo>
            <p class="tc">{{$t('APP_DESC')}}</p>
        </div>
        <p class="splash-sceen__download-app">{{$t('DOWNLOAD_OUR_APP_NOW')}}</p>
        <div class="display-flex flex-col">
              <AppStore :appType="'android'" class="mb-05rem"></AppStore>
              <AppStore :appType="'apple'"></AppStore>
        </div>
      </div>
      <div class="splash-sceen__footer">
          <img class="w-100" src="../../../static/images/splash_sceen_img_1.png">
     </div>
     <div class="splash-sceen__countdown"><span>{{countDownTime}}</span>s</div>
  </div>

</template>

<script>
import MainLogoIcon from '@/components/indicator/MainLogoIcon.vue'
import MainLogo from '@/components/indicator/MainLogo.vue'
import AppStore from '@/components/mobile/app/AppStore.vue'
import { mapGetters, mapActions } from "vuex";
import config from "@/js/config.js";

import moment from 'moment'
export default {
  components: {
    MainLogoIcon,
    MainLogo,
    AppStore
  },
  data() {
    return {
      androidPlayLink: config.app.android.link,
      appleStoreLink: config.app.apple.link,
      isShow: true,
      countDownTime: 3
    }
  },
    computed: {
    ...mapGetters([
      "isMobile",
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    closeSplashSceen(){
      this.isShow = false;
      let params = {
        date: new Date()
      }
      localStorage.setItem("splashSceen",JSON.stringify(params))
    },
    init(){
      let getSplashSceenData = JSON.parse(localStorage.getItem("splashSceen"))
      if (getSplashSceenData) {
        let hours = moment().diff(moment(getSplashSceenData.date), 'hours');        
         if (hours >= 24){
           this.isShow = true;
           this.initCountDown();
         } else {
           this.isShow = false
         }
      } else {
           this.isShow = true;
           this.initCountDown();
      }
    },
    initCountDown(){
      let countDownInterval = setInterval(() => {
        if (this.countDownTime<=0){
          clearInterval(countDownInterval);
          this.closeSplashSceen();
        } else {
            this.countDownTime--;
        }
      
      }, 1000);
    }
  },
  
}
</script>

<style>
.splash-sceen__wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 10;
}
.splash-sceen__close{
    width: 2rem;
    right: 0;
    position: absolute;
    top: 1rem;
}
.splash-sceen__download-app{
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.splash-sceen__body{   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    height: 57vh;
    padding: 0 1rem;
}
.splash-sceen__footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
}
.splash-sceen__countdown{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: 0.1rem solid white;
    padding: 0.2rem 0.8rem;
    border-radius: 0.5rem;
    font-size: .8rem;
}
</style>