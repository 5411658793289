<template>
    <teleport to="body">
        <div v-if="isShow" class="modal-subscription__wrapper  display-flex-center flex-col">
            <transition name="slideUp">
                <div class="modal-subscription__container" v-if="isShowContent" >
                    <div class="modal-subscription__img"><img src="../../../static/images/subscribe_img.png"></div>
                    <div class="modal-subscription__content">      
                        <div  class="modal-subscription__content-close" @click="handleClose()"><img src="../../../static/images/subscribe_close.png"></div>         
                        <!-- <button >Close</button> -->
                        <div class="modal-subscription__content-container">
                            <h2 class="modal-subscription__content-title">Subscribe to our newsletter</h2>
                            <p class="modal-subscription__content-desc">Signup for our weekly newsletter to get the latest news, updates and amazing offers delivered in your inbox</p>
                            <div class="display-flex mb-s">
                                <div class="g-input">
                                    <label><input type="text" class="textfield" :class="{'input-err':emailErrMsg !=''}" required @blur="validateEmail" v-model="email"><span class="placeholder">Email</span></label>
                                
                                </div>
                                <div class="modal-subscription__content-btn-subscribe" @click="handleSubscribe()">Subscribe</div>
                            </div>
                            <div class="modal-subscription__content-err">{{emailErrMsg}}</div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="slideUp" >
                <div class="modal-subscription__info-container" v-if="isShowAlertContent" >
                    <h2 class="modal-subscription__info-title">Thanks for joining us!</h2>
                    <p class="modal-subscription__info-desc">You’ve successfully subscribe to our newsletter.</p>
                    <button class="modal-subscription__info-btn__ok" @click="handleAlertOK">OK</button>
                </div>
            </transition>
        </div>
    </teleport>
</template>

<script>
import moment from 'moment'
import {mapGetters,mapActions} from 'vuex'
export default {
    props: {
        interval: {
            type: Number,
            default : 6 //hours
        },
    },
    data() {

        return {
            localStorageKey : "subscription",
            isLoading:false,
            isShow : false,
            isShowContent : false,

            isShowAlertContent :false,

            email:'',
            emailErrMsg:'',
            isVaild:false
        }
    },
    methods: {
           ...mapActions([
            "getNewsLetterSubscribe",
        ]),
        handleOpen(){
             const result = this.getFromLocalStorage();
             if (result) {
                 let endDate = moment(new Date());
                 let startDate = new Date(result);
                 let duration = moment.duration(endDate.diff(startDate)).asHours();
                 if (duration > this.interval) {
                   
                 } 
                   this.showModal();
             } else {
                this.showModal();
             }
            
        },
        handleClose(){
            this.hideModal();
        },
        showModal(){
            this.isShow = true;
            setTimeout(() => this.isShowContent  = true, 500);
        },
        hideModal(){
            this.isShowContent = false;
            this.saveToLocalStorage();
            setTimeout(() => this.isShow  = false, 500);
        },
        verifyToShowModal(){
            
        },
        saveToLocalStorage(){
            let value = new Date();
            localStorage.setItem(this.localStorageKey,value)
        },
        getFromLocalStorage(){
            let result = localStorage.getItem(this.localStorageKey) === null ? null: localStorage.getItem(this.localStorageKey);
            return result;
        },
        validateEmail() {
            let verify = this.$tools.emailVerification(this.email);

            if (verify) {
                this.emailErrMsg = '';
                this.isVaild = true;              
            } else {
                this.emailErrMsg = 'Please enter a valid email address';
                this.isVaild = false;
            }

        },
        async handleSubscribe(){
          
            if (this.isVaild) {
    
                let params = {
                    email:this.email
                };

                this.isLoading = true;
                const result = await this.getNewsLetterSubscribe(params);
            
                this.isLoading = false;
                if (result.errorCode === 0) {
                
                }
                    this.showAlert();
            }
        
       
        },

        showAlert(){
            this.isShowContent = false;
            setTimeout(() => this.isShowAlertContent  = true, 500)
        },

        handleAlertOK(){
            this.isShowAlertContent = false;
            this.handleClose()
        }

    },
}
</script>

<style>
.modal-subscription__wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0 0 0 / 57%);
    z-index: 10;
}
.modal-subscription__container{
    display: flex;
    width: 55%;
    background-color: #4B616C;
    border-radius:.5rem;
    overflow: hidden;
    height: 25rem;
    max-width: 50rem;
}
.modal-subscription__img{
    width: 42%;
}
.modal-subscription__img img{
    width: 100%;
    height: 100%;
}
.modal-subscription__content{
    position:relative;
    width: 58%
}
.modal-subscription__content-close{
    position:absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
}
.modal-subscription__content-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.modal-subscription__content-desc {
    font-size:.9rem;
    line-height: 1.2rem;
    margin-bottom: 2rem;
}
.modal-subscription__content-container{
   display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding:2rem;
    box-sizing: border-box;
}
.modal-subscription__content-btn-subscribe{
    font-weight: 700;
    background-color: white;
    color: #111618;
    padding: .5rem .8rem;
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
    cursor: pointer;
}
.modal-subscription__content-err{
    font-size: .7rem;
    height: 1.2rem;
    color: #ED9C5A;
}
.input-err{
    border-color:red;
}
.modal-subscription__info-container{
    display: flex;
    width: 55%;
    background-color: #4B616C;
    padding: 1rem;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    height: 25rem;
}
.modal-subscription__info-title{
        margin-bottom: 2.3rem;
    font-size: 2.1rem;
}
.modal-subscription__info-desc{
    margin-bottom: 2rem;
}
.modal-subscription__info-btn__ok{
    width: 50%;
    background-color: white;
    padding: .7rem;
    border-radius: .5rem;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}
</style>